@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    'dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
    'communitySavings communitySavings tableBills tableBills tableBills tableBills tableBills tableBills'
    'energySupplyShare energySupplyShare tableBills tableBills tableBills tableBills tableBills tableBills'
    'selfSufficiency selfSufficiency selfConsumption selfConsumption homeEnergyInfo homeEnergyInfo homeEnergyInfo homeEnergyInfo'
    'energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile';
  box-sizing: border-box;
  grid-gap: 14px;

  .dashboardHeader {
    grid-area: dashboardHeader;
  }

  .communitySavings {
    grid-area: communitySavings;
  }

  .energySupplyShare {
    grid-area: energySupplyShare;
    background-color: $color-vape;
  }

  .tableBills {
    grid-area: tableBills;
  }

  .selfSufficiency {
    grid-area: selfSufficiency;
  }

  .selfConsumption {
    grid-area: selfConsumption;
  }

  .homeEnergyInfo {
    grid-area: homeEnergyInfo;
  }

  .energyProfile {
    grid-area: energyProfile;
  }
}

.worldMap {
  position: relative;
  height: 300px;
}
.theMap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.popupMini {
  margin-top: -30px;
}

@media only screen and (max-width: $xxl) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
      'communitySavings tableBills tableBills tableBills'
      'energySupplyShare tableBills tableBills tableBills'
      'selfSufficiency selfConsumption homeEnergyInfo homeEnergyInfo'
      'energyProfile energyProfile energyProfile energyProfile';
  }
}

@media only screen and (max-width: $xl) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'dashboardHeader dashboardHeader'
      'communitySavings energySupplyShare'
      'tableBills tableBills'
      'selfSufficiency selfConsumption'
      'homeEnergyInfo homeEnergyInfo'
      'energyProfile energyProfile';
  }
}
