@import 'src/assets/styles/utils/index.module.scss';

.orientationAdvanced {
  position: relative;
  overflow: hidden;

  &.light {
    background-color: $color-vape;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    .item {
      .label {
        color: $color-ashy;
      }
    }
  }

  .orientationAdvancedContainer {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .orientationAdvancedInputContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .orientationAdvancedInputWrapper {
    width: calc(50% - 10px);
    min-width: 100px;
    position: relative;

    span {
      position: absolute;
      top: 9px;
      right: 5px;
      color: #85fc99;
      font-size: 14px;
    }

    &.scmWrapper {
      span {
        color: #000;
      }
    }
  }

  .orientationSidebarInputWrapper {
    .orientationAdvancedInputWrapper {
      width: calc(50% - 10px);
    }

    .orientationAdvancedInput {
      width: calc(100%);
    }
    padding-left: 20px;
    padding-right: 20px;
  }

  .orientationAdvancedInput {
    width: calc(50% - 10px);
    min-width: 100px;

    & > div {
      border: none;
      padding: 0;

      & > div {
        display: flex;
        flex-direction: row-reverse;
        border: 0.863415px solid #4b464b;
        border-radius: 4.31707px;
        padding: 8px;

        & > * {
          height: auto;
        }

        & > input {
          text-align: right;
          color: #85fc99;
          padding-right: 5px;
        }

        & > label {
          position: relative;
          transform: none !important;
        }
      }
    }
    &.scm {
      input {
        color: #000;
      }
    }
  }
}
