@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 13px;
  height: 40px;
  background: $color-vape;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 2px;
    height: 17px;
    background: $color-pretty-dark;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .pill {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    gap: 6px;
    width: 56px;
    height: 15px;
    background: $color-white;
    border-radius: 5px;

    font-size: 9px;
    line-height: 7px;
    color: $color-ashy;
  }

  .assetIcon {
    padding: 0 10px;
    width: 35px;
    height: 35px;
    color: $color-metal-grey;
    margin-right: 0.5rem;
  }

  .assetTitle {
    font-family: $ff-sans;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $color-pretty-dark;
  }

  .assetUser {
    font-family: 'NB Akademie Std';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: $color-metal-grey;
  }
  .input {
    padding: 0;
    input {
      height: 20px;
    }
  }
}
