@import 'src/assets/styles/utils/index.module.scss';

.registry {
  overflow-y: scroll;
  height: 100%;
}

.container {
  height: 109%;

  .withMargin {
    margin: 0px 15px 15px 15px;
  }

  .disableContainer {
    pointer-events: none;
    opacity: 0.5;
    backdrop-filter: blur(2px);
  }
  .addAssetContainer,
  .headerContainer {
    .innerHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
    }

    h1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $color-ashy;
    }

    .heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $color-ashy;
      margin-bottom: 1rem;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $color-metal-grey;
    }
  }

  .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    .iconBack {
      margin-right: 10px;
    }
  }

  .formButtonsWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    padding-top: 10px;
    background-color: $color-snow-in-april;

    button {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
