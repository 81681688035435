@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  width: 100%;
}

.overlayer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
