@import 'src/assets/styles/utils/index.module.scss';

.orientation {
  position: relative;
  padding-top: 10px;

  .tabNav {
    button {
      font-size: 14px !important;
    }
  }
}
