@import 'src/assets/styles/utils/index.module.scss';

.customPV {
  margin: 15px 0;

  &.light {
    .container {
      background: none !important;
      padding: 0px;
      .accordion {
        padding: 10px;
        background: $color-vape;
        border-radius: 5px;
        .title {
          color: $color-ashy;
        }
      }
    }
  }
  .hint {
    padding-top: 10px;
  }
  .container {
    background: $color-ashy;
    border-radius: 4px;
    padding: 10px;
    margin: 6px 0;
    .accordion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      user-select: none;

      .title {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $color-snow-in-april;
        letter-spacing: 0.4px;
      }

      .arrowIcon {
        color: $color-metal-grey;
      }

      .arrowIcon.arrowOpen {
        transform: rotate(180deg);
      }
    }
  }
}

.tabs {
  margin-bottom: 15px;
}
