@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $color-snow-in-april;

  @media (min-width: 500px) {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 14px;
  margin: 0 0 10px;

  h3 {
    font-size: 1.8rem;
  }

  & > div {
    flex-shrink: 0;
  }
}

.profile,
.graphsList,
.settingsList,
.registry {
  overflow-y: scroll;
  height: 100%;
  padding: 15px 14px 15px;
}

.overflowYAuto {
  overflow-y: auto !important;
}

.graphsList {
  &.scmResultsContainer {
    padding-top: 0px;
  }
  & > div {
    margin-bottom: 20px;
  }
}

.settingsList {
  overflow-y: scroll;
  height: 100%;
  padding: 0 14px;
}
.withNotoficationBubble {
  position: relative;
}
.notificationsBubble {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 0;
  background-color: $color-singularity-red;
}

.notificationsBubbleText {
  display: block;
  color: $color-white;
  font-size: 1.1rem;
  line-height: 1.2rem;
}

.headerNav {
  display: flex;
  font-size: 1.5rem;
  width: 100%;
  margin-bottom: 10px;

  li {
    margin-right: 18px;
    padding: 4px 0;
    color: $color-metal-grey;
    &.membersWithConnectTooltip {
      [class^='styles-module_arrow_'] {
        top: 10px !important;
      }
      [role='tooltip'] {
        z-index: 999 !important;
      }
    }
    &.communityName {
      overflow: hidden;

      button {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.active {
      border-bottom: 2px solid $color-oh-so-green;
      color: $color-pretty-dark;
      [class^='styles-module_arrow_'] {
        top: 10px !important;
      }
    }
    &.hasNotifications {
      color: $color-pretty-dark;
    }
  }
}

.sidebarIconButton {
  position: absolute;
  right: 8px;
}

.formWrapper {
  margin-bottom: 50px;
}

.formButtonsWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  background-color: $color-snow-in-april;
}

.formButton {
  width: 100%;
  border-radius: 0;
}

.settingsActionButton {
  justify-content: right;
  width: 100%;
  padding: 0;
  background-color: transparent;
  color: $color-kinda-dirty;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.settingsActionButtonBorder {
  border-bottom: 1px solid $color-london-fog;
}

.boxSidebarAlert {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 12px;
  border-radius: 4px;
  background-color: $color-pretty-dark;
  margin-bottom: 15px;

  h3 {
    color: $color-snow-in-april;
    font-weight: $fw-bold;
    font-size: 1.25rem;
  }

  p {
    margin-top: 5px;
    color: $color-metal-grey;
    font-weight: $fw-regular;
    font-size: 1.1rem;
  }
}

.iconCanary {
  border-radius: 4px;
  box-shadow: 3px 0 12px rgba(22, 65, 247, 0.8);
}
.boxText {
  margin: 0 10px;
  flex-grow: 10;
}

.buttonGo {
  width: 36px;
  height: 33px;
  flex-grow: 1;
}

.subtabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px 14px;
  margin-bottom: 0 !important;

  h3 {
    font-size: 1.4rem;
  }

  & > div {
    flex-shrink: 0;
  }

  ul {
    margin-bottom: 10px;

    li {
      color: #a0a0ac;
      font-weight: normal;
      font-size: 1.5rem;
    }
  }
}

.liveResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid $color-singularity-red;
  border-radius: 4px;
  padding: 6px;

  .text {
    font-size: 14px;
    line-height: 16px;
    color: $color-singularity-red;

    &::before {
      content: '';
      position: relative;
      right: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-singularity-red;
    }
  }
}

.tooltipContent {
  width: 200px;
  font-family: $ff-sans !important;
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .graphsList {
    height: calc(100vh - 155px);
  }
  .header {
    margin-bottom: 0px;
    height: 50px;
  }
  .subtabHeader {
    padding-left: 0px;
    padding-right: 0px;
  }

  .profile {
    height: calc(100vh - 155px);
  }
}

@media only screen and (max-width: 768px) {
  .headerNav {
    li {
      margin-right: 15px;
      font-size: 1.5rem;
    }
  }
}

.scmMessagingPortal {
  display: flex;
}
