@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  background-color: $color-white;
  height: 100%;

  .header {
    display: flex;
    align-items: center;

    .headerContent {
      display: flex;
      align-items: center;
      width: 100%;
    }

    h3 {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.7rem;
    }
  }
}

.content,
.footer {
  height: 100%;
  display: flex;
  align-items: center;
}

.footer {
  margin-top: 15px;
}
