@import 'src/assets/styles/utils/index.module.scss';

.theme-1 {
  --color-text: #{$color-metal-grey};
  --color-text-active: #{$color-ashy};
  --color-underline: #{$color-oh-so-green};
}

.theme-2 {
  --color-text: #{$color-metal-grey};
  --color-text-active: #{$color-snow-in-april};
  --color-underline: #{$color-oh-so-green};
}

.theme-3 {
  --color-text: #{$color-metal-grey};
  --color-text-active: #{$color-snow-in-april};
  --color-underline: #{$color-singularity-red};
}

.theme-4 {
  --color-text: #{$color-ashy};
  --color-text-active: #{$color-ashy};
}

.theme-static {
  --color-text: #{$color-metal-grey};
  --color-text-active: #{$color-metal-grey};
}

.container {
  position: relative;
  color: var(--color-text);
  font-weight: $fw-medium;
  font-size: 1.6rem;
  text-align: center;

  &:global(.active) {
    color: var(--color-text-active);
    font-weight: $fw-bold;
  }
}

.active-style-underlined {
  &:not(.theme-4),
  &:not(.theme-static) {
    &::after {
      content: '';
      position: absolute;
      bottom: -7.5px;
      left: 0;
      width: 100%;
      height: 3px;
      background: var(--color-underline);
      transition: transform 0.2s $ease-out-quad;
      transform: scaleX(0);
    }
  }

  &:not(:disabled):hover {
    &::after {
      transition: transform 0.2s $ease-in-quad;
      transform: none;
    }
  }

  &:global(.active) {
    &::after {
      transform: none;
    }
  }
}
