@import 'src/assets/styles/utils/index.module.scss';

.assetsList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 16px;
}

.assetsList {
  .assetTile {
    margin-bottom: 8px;
    gap: 10px;
  }
}

.assetTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: thin solid $color-vape;
  border-radius: 4px;
  background-color: $color-vape;

  &.selected {
    border-color: $color-oh-so-green;
  }
}

.menuIcon {
  cursor: pointer;
}

.assetTile {
  position: relative;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;

  .clickable:hover {
    cursor: pointer;
  }

  &.isChildren {
    width: calc(100% - 22px);
  }

  &:not(.isChildren) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 17px;
      background-color: $color-ace-blue;
      transform: translateY(-50%);
    }
  }

  .leftContainer {
    display: flex;
    align-items: center;
    flex: 1;

    .assetUser {
      font-family: 'NB Akademie Std';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: $color-metal-grey;
    }
  }

  .icon {
    color: $color-metal-grey;
    margin-right: 12px;
  }

  .name {
    flex: 1;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }

  .childAssetsCount {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75px;
    height: 16px;
    padding: 0 5px;
    border-radius: 4px;
    background-color: $color-white;
    color: $color-ashy;
    font-weight: 500;
    font-size: 9px;
    line-height: 7px;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-left: 5px;

    > svg {
      color: $color-metal-grey;
    }
  }

  .actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    border-radius: 100%;
    background-color: $color-oh-so-green;
  }

  .actionButtonDecline {
    background-color: $color-singularity-red;
  }

  .selectbox {
    max-width: 120px;
    margin-left: 10px;
  }
}

.acceptOrRejectContainer {
  display: flex;
  margin-right: 5px;

  & > button {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    color: $color-black;
    margin-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-of-type(1) {
      background-color: $color-oh-so-green;
    }
    &:nth-of-type(2) {
      background-color: $color-singularity-red;
    }
  }
}

.wifiCircle {
  background-color: $color-metal-grey;
  color: $color-ashy;
  border-radius: 10px;
  display: block;
  position: relative;
  overflow: hidden;

  &.wifiExecuted {
    background-color: $color-ace-blue;
    padding: 5px;
  }
  &.withCirclePadding {
    padding: 5px;
  }
  &.withCircleClickable {
    cursor: pointer;
  }
  &.rejectedWrapper {
    .rejectedIcon {
      margin-left: 0px;
      height: 22px;
      width: 22px;
    }
  }
  &.withHoverDelete {
    .rejectedIcon {
      position: absolute;
      opacity: 0;
      z-index: 999999;
      left: 0px;
      top: 0px;
      margin-left: 0px;
      width: 22px;
      height: 22px;
    }
    &:hover {
      .rejectedIcon {
        opacity: 1;
      }
      .wifiLoadFluLayer {
        opacity: 0;
      }
      .wifiLoadIcon {
        opacity: 0;
      }
      .wifiNotConnected {
        opacity: 0;
      }
    }
  }
  .wifiLoadIcon {
    width: 11px;
    height: 11px;
    background-color: $color-oh-so-green;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .wifiLoadFluLayer {
    height: 25px;
    width: 25px;
    position: absolute;
    background-color: $color-pretty-dark;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.4;
  }
}

.rejectedIcon {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  color: $color-black;
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-singularity-red;
}
