@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    'dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
    'keyResultsMember keyResultsMember keyResultsMember keyResultsMember homeEnergyInfo homeEnergyInfo homeEnergyInfo homeEnergyInfo'
    'energyBillComponents energyBillComponents energyBillComponents energyBillComponents selfSufficiency selfSufficiency selfConsumption selfConsumption'
    'energyBillComponents energyBillComponents energyBillComponents energyBillComponents energySupplyShare energySupplyShare keyResultProgress keyResultProgress'
    'energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile';
  box-sizing: border-box;
  grid-gap: 14px;

  .dashboardHeader {
    grid-area: dashboardHeader;
  }

  .keyResultsMember {
    grid-area: keyResultsMember;
  }

  .homeEnergyInfo {
    grid-area: homeEnergyInfo;
  }

  .energyBillComponents {
    grid-area: energyBillComponents;
  }

  .selfSufficiency {
    grid-area: selfSufficiency;
  }

  .selfConsumption {
    grid-area: selfConsumption;
  }

  .keyResultProgress {
    grid-area: keyResultProgress;

    .savingsRanking {
      background-color: $color-white;
      padding-top: 24px;
      padding-bottom: 32px;
      header {
        margin-bottom: 50px;
      }
    }
  }

  .energySupplyShare {
    grid-area: energySupplyShare;
    background-color: $color-white;
  }

  .energyProfile {
    grid-area: energyProfile;
  }
}

@media only screen and (max-width: $xxl) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
      'keyResultsMember keyResultsMember homeEnergyInfo homeEnergyInfo'
      'energyBillComponents energyBillComponents selfSufficiency selfConsumption'
      'energyBillComponents energyBillComponents energySupplyShare keyResultProgress'
      'energyProfile energyProfile energyProfile energyProfile';
  }
}

@media only screen and (max-width: $xl) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'dashboardHeader dashboardHeader'
      'keyResultsMember keyResultsMember'
      'homeEnergyInfo homeEnergyInfo'
      'energyBillComponents energyBillComponents'
      'selfSufficiency selfConsumption'
      'keyResultProgress keyResultProgress'
      'energySupplyShare energySupplyShare'
      'energyProfile energyProfile';
  }
}
