@import 'src/assets/styles/utils/index.module.scss';

.container {
  background: #fbf9f9;
  padding: 5px 0px;
  height: 90%;
}
.initialContainer {
  font-size: 20px;
  color: $color-white;
  position: relative;
  width: 100%;
  height: 100%;
  background: $color-ace-blue;
  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    text-align: center;
  }
}

.userTileContainer {
  display: flex;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &.newUser {
    padding: 3rem 1rem 5rem 1rem;
  }

  .imgContainer {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background: $color-vape;
    margin-right: 1.3rem;
    border: 1px dashed $color-metal-grey;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoContainer {
    position: relative;
    //min-width: calc(100% - 9rem);
    width: 100%;

    .fieldContainer {
      margin-bottom: 35px;
    }
    .textContainer {
      margin-bottom: 10px;
    }
    .locationSearch {
      width: 100%;
    }
    .name {
      font-family: $ff-sans;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $color-ashy;
    }
    .email {
      font-family: $ff-sans-landingPage;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      margin-bottom: 15px;
      color: $color-metal-grey;
    }
    .address {
      display: flex;
      font-family: $ff-sans;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      .iconMap {
        margin-right: 5px;
        color: $color-singularity-red;
      }
    }
  }
  .actionsContainer {
    display: flex;
    align-items: center;
    position: absolute;
    right: -1rem;
    top: -3rem;

    .button {
      margin-left: 8px;
      color: $color-black;
    }
  }
}

.listMemberAssets {
  border-top: 1px solid $color-vape;
  margin-top: 2.5rem;
}

.assetsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.selectbox {
  max-width: 100px;
  position: relative;
  top: -3.5rem;
}

.noAssetsContainer {
  height: 15rem;
  position: relative;

  .noAssetPill {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    background: $color-london-fog;
    color: $color-ashy;
    border-radius: 4px;
    padding: 10px 30px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }
}

.addAssetButton {
  position: sticky;
  bottom: 0;
  height: 32px;
  width: 100%;
  background-color: $color-ashy;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-snow-in-april;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
  }
  span {
    opacity: 0.5;
    margin-left: 5px;
    font-weight: 400;
    font-size: 1rem;
  }
}

.formButton {
  width: 100%;
}

.OKButton {
  width: 30px;
  font-size: 10px;
  height: 25px;
  margin-top: 5px;
  margin-left: 10px;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

.topButtonRight {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
}
