@import 'src/assets/styles/utils/index.module.scss';

.navigation {
  margin-bottom: 20px;

  ul {
    display: flex;

    li {
      margin-right: 20px;
      color: $color-ashy;
      font-size: 1.4rem;

      &.active {
        position: relative;
        color: $color-pretty-dark;
        font-weight: $fw-bold;

        &::after {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $color-oh-so-green;
        }
      }
    }
  }
}
