@import 'src/assets/styles/utils/index.module.scss';

.usersList,
.assetsList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 16px;
}

.usersList {
  .userTile {
    margin-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }

  .assetTile {
    margin-bottom: 6px;
  }
}

.userTile,
.assetTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: thin solid $color-vape;
  border-radius: 4px;
  background-color: $color-vape;

  &.selected {
    border-color: $color-oh-so-green;
  }
}

.userTile {
  height: 55px;
  padding-right: 8px;
  padding-left: 12px;

  &:hover {
    cursor: pointer;
  }

  .username {
    flex: 1;
    padding-left: 12px;
  }

  .userDataContainer {
    flex: 2;

    .userEmail {
      flex: 1;
      padding: 4px 0 0 12px;
      color: $color-metal-grey;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .newUserBlock {
      background: $color-ashy;
      color: $color-snow-in-april;
      display: flex;
      justify-content: center;
      padding: 4px 5px;
      border-radius: 4px;
      margin-right: 1.6rem;
    }

    .selectbox {
      width: 100px;
    }

    .multiselect {
      display: flex;

      &.firstHomeAssign {
        .selectbox {
          width: 130px;
        }
      }
    }

    .joinState {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $color-metal-grey;
      color: $color-pretty-dark;

      &.accepted {
        background-color: $color-oh-so-green;
      }
    }
  }
}
