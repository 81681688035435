@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 15px;
  background-color: $color-vape;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  gap: 15px;
  margin-top: 10px;

  &.hideContent {
    opacity: 0;
  }
}

.doughnut {
  position: relative;
  width: 64px;
  height: 64px;

  .doughnutText {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.7rem;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}

.legendContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .legend {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .legendDot {
      width: 7px;
      height: 7px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .legendLabel {
      word-wrap: break-word;
      flex-grow: 20;

      p {
        width: 87px;
        margin-left: 4px;
        color: $color-ashy;
        font-size: 1.1rem;
        line-height: 1.3rem;
      }

      .height18 {
        line-height: 1.6rem;
      }
    }

    span {
      width: 86px;
      margin-left: 11px;
      padding: 4px;
      border-radius: 35px;
      background-color: $color-ashy;
      color: $color-white;
      font-size: 1.1rem;
      line-height: 1.2rem;
      text-align: center;
      flex-grow: 5;
    }
  }
}

@media only screen and (max-width: 768px) {
  .content {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .simulationItem {
    flex-direction: column;
    span {
      margin-left: 0px;
    }
  }
}
