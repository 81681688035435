@import 'src/assets/styles/utils/index.module.scss';

.tabsWrapper {
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 1.4rem;
}

.tab {
  & + & {
    margin-left: 30px;
  }
}
