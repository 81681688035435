@import 'src/assets/styles/utils/index.module.scss';

@media only screen and (max-width: 768px) {
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .mobileModal {
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    left: 0px;
    top: 72px;
    background-color: $color-pretty-dark;
    padding: 40px;
    padding-top: 60px;
    animation: slide-up 0.3s ease-in-out;
    border-radius: 10px 10px 0px 0px;
  }

  .closeIcon {
    color: $color-white;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
  }
}
