@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0px 15px 15px 15px;

  .wrapper {
    margin-bottom: 50px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-size: 1.6rem;
    line-height: 20px;
    font-weight: 500;
    border-bottom: 2px solid $color-oh-so-green;
    cursor: pointer;
  }
  & > div {
    display: flex;
    button {
      margin-left: 15px;
    }
  }
}

.hero {
  width: 100%;
  height: 300px;
  background-color: $color-vape;
  border-radius: 5px;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .buttonContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      button {
        margin-left: 10px;
        color: $color-black;
        background-color: $color-ccc;
      }
    }

    .userPill {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 7px;
      background: #fbf9f9;
      border-radius: 49px;

      .imgContainer {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $color-vape;
        margin-right: 1rem;
        border: 1px dashed $color-metal-grey;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      span {
        font-family: $ff-sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-ace-blue;
      }
    }
  }
  .icon {
    flex: 1;
    svg {
      height: 100%;
    }
  }
  .address {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-ashy;
    margin: 1rem 0;
  }
  p {
    font-size: 1.6rem;
    line-height: 20px;
  }
}

.assetsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  .content {
    flex: 1;
  }
}

.settings {
  flex: 1;
  margin-bottom: 50px;
}

.search {
  margin-bottom: 10px;
}

.assetListItem {
  height: 40px;
  display: flex;
  padding: 0 8px 0 12px;
  position: relative;
  background-color: $color-vape;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  border-radius: 4px;
  &.isSelected {
    box-shadow: 0 0 0 1px $color-oh-so-green;
  }
  &.isSelectable {
    cursor: pointer;
    user-select: none;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 18px;
    background-color: $color-pretty-dark;
  }
  & > svg {
    color: $color-metal-grey;
  }
  p {
    flex: 1;
    padding-left: 12px;
    font-weight: 700;
  }
}

.addAssetButton {
  width: 100%;
  height: 32px;
  margin: 2rem 0;
  background-color: $color-ashy;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-snow-in-april;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;

  svg {
    margin-right: 10px;
  }
  span {
    opacity: 0.5;
    margin-left: 5px;
    font-weight: 400;
    font-size: 1rem;
  }
}

.tabs {
  margin-top: 15px;
}

.dataStreamId {
  margin-top: 25px;
}
