@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    padding: 0 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid $color-vape;

    h3 {
      font-size: 1.8rem;
    }

    & > div {
      flex-shrink: 0;
    }
  }
  .headingText {
    font-family: $ff-sans;
    line-height: 19px;
    color: $color-ashy;
    margin-bottom: 15px;
    font-weight: $fw-bold;
    font-size: 1.6rem;
  }

  .selectField {
    margin-bottom: 20px;
  }
}

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding: 15px 0;
  border-top: thin solid rgba($color-metal-grey, 0.1);

  & + & {
    border-bottom: thin solid rgba($color-metal-grey, 0.1);
  }
}

.switch {
  width: 100%;
}

.inputField,
.checkboxField,
.sliderField,
.locationField,
.selectField,
.fileUploadField {
  margin-top: 20px;
}

.counter {
  margin-top: 20px;
}

.marginTopSmall {
  margin-top: 15px;
}

.sevenDaysTag {
  min-width: 72px;
}
.inputValue {
  font-size: 13px;
  color: $color-ashy;
}

.datePickerContainer {
  margin-bottom: 50px;
}

.datePickerNoMargin {
  margin-top: 0;
}

.lengthWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 8px;
}

.lengthText {
}

.lengthTooltip {
  margin-left: 8px;
}

.locationSearchDropdown {
  max-height: 85px;
}
