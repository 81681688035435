.singleNotificationContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .singleCharacterContainer {
    font-size: 22px;
  }
  span {
    display: flex;
    font-size: 14px;
    font-weight: 500;
  }
}

.requestSentNotificationContainer {
  width: 350px;
  padding: 10px;
}
