@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 500;
  display: flex;
  flex-direction: column;
  height: 35px;
  bottom: 0;
  color: #a0a0ac;
  font-size: 14px;
  font-weight: 500;

  footer {
    text-align: center;
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .mobileRelative {
    position: relative;
    display: block;
    height: 60px;
    margin-bottom: 65px !important;
    font-size: 12px;
  }
}
