@import 'src/assets/styles/utils/index.module.scss';

.wrapper {
  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    padding: 1rem;
    padding-top: 2rem;
    margin-top: 1rem;
    gap: 2rem;
  }
}

.brushArea {
  display: flex;
  .selectWrapper {
    flex: 2;
    max-width: 110px;
    .select {
      background-color: $color-vape;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 2px;
    }
  }
  .brushWrapper {
    position: relative;
    flex: 7;
  }
}

.badgeRow {
  flex-direction: row;
  .bgRed {
    background-color: $color-singularity-red;
  }
  .bgGreen {
    background-color: $color-oh-so-green;
  }
  .bgBlue {
    background-color: $color-ace-blue;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    .container {
      overflow-y: scroll;
      .brushArea {
        width: 100%;
      }
      .chartArea {
        width: 300px;
        position: relative;
      }
    }
  }
}
