@import 'src/assets/styles/utils/index.module.scss';

.editAsset {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .container {
    margin: 0px 15px 15px 15px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        font-size: 1.8rem;
      }

      .cancelButton {
        padding: 5px;
        height: auto;
        width: auto;

        &.assetCancel {
          border-radius: 50%;
          margin-left: auto;
          margin-right: 0;
        }

        .svgIcon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .saveSettings {
    width: 100%;
    padding: 22.5px;
    position: sticky;
    left: 0;
    bottom: 0;
    margin-top: 5px;
    background-color: $color-oh-so-green;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
