@import 'src/assets/styles/utils/index.module.scss';

.languageLabel {
  font-size: 1.2rem;
  font-weight: 500;
  color: $color-ashy;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  &:hover {
    cursor: pointer;
  }
  .labelText {
    display: flex;
    width: 60px;
  }
}
.code {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: $color-oh-so-green;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  line-height: initial;
  margin-top: -2px;
}

.languageSelectContainer {
  //create an absolute positioned container for the language select
  position: absolute;
  top: 70px;
  //set the width of the container to the width of the language select
  width: 100px;
  //set the height of the container to the height of the language select
  height: 100%;
  //set the z-index of the container to 1
  z-index: 1;
  display: flex;
  flex-direction: column;

  .selectButton {
    background-color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: #000;
      color: #fff;
    }

    &.active {
      background-color: #000;
      color: #fff;
    }
  }
}
