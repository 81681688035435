@import 'src/assets/styles/utils/index.module.scss';

.mainWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.infoAreaWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: $color-vape;
  align-content: center;
  border-radius: 0.5rem;
  padding: 0.6rem;
  gap: 2rem;
  .iconWrapper {
    display: flex;
    flex: 2 1;
    justify-content: center;
    .mainIcon {
      max-width: 13rem;
    }
  }
  .textArea {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    h5 {
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .infoAreaWrapper {
    .iconWrapper {
      .mainIcon {
        max-width: 100%;
      }
    }
  }
}
