@import 'src/assets/styles/utils/index.module.scss';

.newAsset {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.withMarginTop {
    margin-top: 15px;
  }

  .container {
    margin: 0px 15px 15px 15px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        font-size: 1.8rem;
      }
    }
  }

  .cancelButton {
    padding: 5px;
    height: auto;
    width: auto;

    &.assetCancel {
      border-radius: 50%;
      margin-left: auto;
      margin-right: 0;
    }

    .svgIcon {
      width: 12px;
      height: 12px;
    }
  }

  .typeSelector {
    margin-bottom: 10px;
    h5 {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 8px;
    }
    p {
      font-size: 1.2rem;
      font-weight: 400;
      color: $color-metal-grey;
      max-width: 260px;
    }
  }

  .saveSettings {
    width: 100%;
    padding: 22.5px;
    position: sticky;
    left: 0;
    bottom: 0;
    margin-top: 5px;
    background-color: $color-oh-so-green;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hero {
  // also used in SingleAsset
  width: 100%;
  height: 230px;
  background-color: $color-vape;
  border-radius: 5px;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-size: 1.6rem;
  }
}
