@import 'src/assets/styles/utils/index.module.scss';

$padding: 10px;

.modal {
  position: absolute;
  // top: 50%;
  // left: -200px;
  // transform: translate3d(-40%, -135%, 0);

  &.modalTooltip {
    // top: 50%;
    // left: -200px;
    transform: translate3d(-53%, -150%, 0);
    &::after {
      content: '';
      position: absolute;
      top: 99%;
      left: 50%;
      border-width: 8px;
      border-style: solid;
      border-color: $color-ashy transparent transparent transparent;
    }
  }
}
.pointerEvents {
  pointer-events: none !important;
}
.modalInner {
  min-width: 250px;
  border-radius: 8px;
  color: #{$color-white};
  pointer-events: auto;
}

.formMaxHeight {
  max-height: var(--assets-manager-scroll-max-height);
}

.paddingAround {
  padding: $padding;
}

.paddingLR {
  padding: 0 $padding 0 $padding;
}

.paddingT {
  padding-top: $padding;
}

.paddingB {
  padding-bottom: $padding;
}

.addHouseContainer {
  min-width: 165px;
  padding: 15px;
}

.address {
  color: $color-vape;
  font-weight: $fw-bold;
  font-size: 1.2rem;
  font-family: $ff-sans-secondary;
  line-height: 1.4rem;
  white-space: nowrap;
}

.scmPinContainer {
  min-width: 165px;
  padding: 15px;
  display: flex;
  align-items: center;

  svg {
    color: $color-oh-so-green;
    margin-right: 5px;
  }

  .changeLink {
    color: $color-oh-so-green;
    margin-left: 20px;
    font-size: 1.2rem;
    font-family: $ff-sans-secondary;
    line-height: 1.4rem;
    cursor: pointer !important;
  }
}

.addHouseBtn {
  width: 100%;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: $color-snow-in-april;
}

.title {
  overflow: hidden;
  max-width: 13em;
  margin-right: 20px;
  font-weight: $fw-bold;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.typeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: $color-snow-in-april;
  color: $color-ashy;
}

.mla {
  margin-left: auto;
}

.mlaClose {
  margin-left: 10px;
}

.autoLeft {
  margin-left: auto;
}

.assetsList {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-right: 84px;
}

.assetSquareBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 7px;
  border-radius: 4px;
  background: $color-ashy;

  &:disabled {
    cursor: default;
  }
}

.assetsAmount {
  position: absolute;
  bottom: -5px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 17px;
  height: 9px;
  padding: 0 4px;
  border-radius: 35px;
  background: $color-metal-grey;
  color: $color-pretty-dark;
  font-weight: $fw-medium;
  font-size: 0.7rem;
  transform: translateX(-50%);
}

.addAssetBtn {
  background: $color-metal-grey;
  color: $color-black;
  composes: assetSquareBtn;
}

.runSimBtn {
  width: 100%;
}

.saveBtn {
  width: 100%;
  margin-top: 11px;
}

.keepBtn {
  width: 100%;
  margin-top: 20px;
}

.addNewAssetBtn {
  width: 100%;
  margin-top: 15px;
}

.chosenAsset {
  margin-bottom: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.abortBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 35px;
  margin-right: 5px;
  border-radius: 4px;
  background: $color-metal-grey;
  color: $color-white;
}

.chart {
  width: 250px;
  min-width: 100%;
}

.noAssetsBadge {
  display: inline-flex;
  align-items: center;
  height: 23px;
  margin-top: 15px;
  padding: 0 10px;
  border-radius: 4px;
  background: $color-ashy;
  font-size: 1.2rem;
}

.saveOrSettings {
  width: 250px;
  min-width: 202px;
  padding: 10px 0;
  color: #fbf9f9;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4rem;
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;
}

.loadingAroundWrapper {
  padding: $padding;
  height: 70px;
}

// draw a triangle by using a div
.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  margin: auto;
  left: 0px;
  right: 0px;
}

.modalMiniTooltip {
  top: 0px;
}

.scmUserTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  // create a circle by using a div with a border-radius of 50% and dashed border
  .summaryUserCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px dashed #ccc;
    margin-right: 10px;
  }
}
