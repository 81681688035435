@import 'src/assets/styles/utils/index.module.scss';

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding: 15px 0;
  border-top: thin solid rgba($color-metal-grey, 0.1);

  & + & {
    border-bottom: thin solid rgba($color-metal-grey, 0.1);
  }
}

.switch {
  width: 100%;
}

.counter {
  margin-top: 20px;
}

.marginTopSmall {
  margin-top: 15px;
}

.sevenDaysTag {
  min-width: 72px;
}

.datePickerContainer {
  margin-bottom: 50px;
}

.datePickerNoMargin {
  margin-top: 0;
}

.lengthWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 8px;
}

.lengthText {
}

.lengthTooltip {
  margin-left: 8px;
}

.locationSearchDropdown {
  max-height: 85px;
}

.locationLabelPositioner {
  label {
    margin-left: -18px;
  }
}

.inputFieldWithLabel {
  display: flex;
  flex-direction: column;
  gap: 20px;

  header {
    display: flex;
    align-items: center;
    color: $color-pretty-dark;
  }

  .label {
    overflow: hidden;
    max-width: 14em;
    margin-right: 20px;
    font-family: $ff-sans;
    font-weight: $fw-bold;
    font-size: 1.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 19px;
  }
}

.inputFieldWithOk {
  display: flex;
  flex-direction: row;

  .OKButton {
    width: 30px;
    font-size: 10px;
    height: 25px;
    margin-top: 5px;
    margin-left: 10px;
  }
}
