@import 'src/assets/styles/utils/index.module.scss';

.block {
  border-radius: 5px;
  background-color: $color-vape;
}

.assetInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 18px;

  header {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    height: 25px;

    .earningStatus {
      color: $color-ashy;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
    }

    .monthlyTag {
      height: 6px;
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 9px;
      line-height: 18px;
      color: $color-ace-blue;
      display: flex;
      align-items: center;
      text-align: center;
      border: 1px solid $color-ace-blue;
      padding: 8px;
      border-radius: 5px;
    }
    .toolTipWrapper {
      position: absolute;

      .toolTip {
        position: absolute;
        top: 18%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 14;
        font-size: 14px;

        @media only screen and (min-width: 1920px) and (min-height: 1080px) {
          top: 27%;
        }
      }
    }
  }

  .cost {
    display: flex;
    align-items: center;
    height: 100%;

    margin-top: 10px;
    color: $color-ashy;
    font-family: 'NB Akademie Std', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $color-ashy;
    margin-top: 1rem;
    display: flex;
  }
}
