@import 'src/assets/styles/utils/index.module.scss';

$padding: 10px;

.container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
  flex: 1;
}
.rowWrapper {
  flex: 50%;
  width: 50%;
  padding: 20px;
}
.rowFullWrapper {
  flex: 100%;
  width: 100%;
  padding: 20px;
}
.messageHeader {
  cursor: pointer;
  display: inline-block;
  width: auto;
  align-items: center;
  border-bottom: 2px solid #6e6;
  padding-bottom: 5px;
  h3 {
    font-weight: 500;
    font-size: 18px;
  }
}
.searchAndSelect {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 17px;

  .input {
    flex: 1;
  }
}
.messageContainer {
  padding: 0 0 10px;
}
.messageListWrapper {
  height: calc(100vh - 350px);
}
.formWrapper {
  height: calc(100vh - 280px);
}

.subHeading {
  padding: 10px 0 0;
  font-size: 14px;
  line-height: 16px;
}
.noMessages {
  width: 130px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
}
.messageItem {
  position: relative;
  overflow: hidden;
  min-height: 90px;
  color: #34363f;
  font-weight: 500;
  margin: 10px 0;
  border-bottom: 1px solid $color-london-fog;
  padding: 10px;
  &.newMesssage {
    background-color: #efeff1;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    padding: 0 0 5px;
    span {
      font-size: 12px;
      line-height: 15px;
      color: #808191;
    }
  }
  p {
    font-size: 14px;
    line-height: 19px;
    color: #808191;
  }
}
.formButtonsWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  background-color: $color-snow-in-april;
}

.formButton {
  width: 100%;
  border-radius: 0;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  font-family: $ff-sans;
  font-size: 12px;
  line-height: 14px;
  color: $color-ashy;
}

.paddingLR {
  padding: 0 $padding 0 $padding;
}

.header {
  display: flex;
  align-items: center;
  color: $color-pretty-dark;
  // padding: 15px 0;
  margin: 25px 0 10px 0;
}

.title {
  overflow: hidden;
  max-width: 13em;
  margin-right: 20px;
  font-family: $ff-sans;
  font-weight: $fw-bold;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 19px;
}

.selectField {
  margin: 20px 0;
}

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: -1px;
  // border-top: thin solid rgba($color-metal-grey, 0.1);

  & + & {
    border-bottom: thin solid rgba($color-metal-grey, 0.1);
  }
}

.attachment {
  display: inline-block;
  background: #e8e8e9;
  padding: 10px;
  margin: 10px 0 0;
  cursor: pointer;
}
.attachmentContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    padding: 5px;
    background-color: $color-soft-not-so-green;
  }
}
@media only screen and (max-width: 768px) {
  .messageListWrapper {
    height: calc(100vh - 440px);
  }
}
