@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $color-white;
}

.header {
  padding: 15px 30px 20px;
  border-bottom: thin solid $color-vape;

  h2,
  h3 {
    color: $color-ace-blue;
    font-weight: $fw-medium;
    font-family: $ff-sans-secondary;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 3rem;
    line-height: 3.7rem;
  }

  h3 {
    font-size: 3.4rem;
    line-height: 3.7rem;
  }

  button {
    display: inline-block;
    margin-left: 24px;
  }
}

.listHeader {
  padding: 20px 30px 15px;

  .simulationFilterContainer {
    margin-top: 22px;
  }

  nav {
    margin-bottom: 20px;

    ul {
      display: flex;

      li {
        margin-right: 20px;
        color: $color-ashy;
        font-size: 1.4rem;

        &.active {
          position: relative;
          color: $color-pretty-dark;
          font-weight: $fw-bold;

          &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $color-oh-so-green;
          }
        }
      }
    }
  }
}

:global(body) {
  &:not(:global(.logged-in)) {
    .communitiesList {
      .communitiesListWindow {
        padding-top: 15px;
      }
    }
  }
}

.communitiesList {
  [class^='styles-module_arrow_'] {
    top: 20px !important;
  }
  .communitiesListWindow {
    @media (min-width: 500px) {
      padding: 0 10px 0 30px;
    }

    & > div {
      position: relative;
    }

    .listItem {
      margin-bottom: 10px;
    }
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .list {
    flex: 1;
  }
}

.tooltipContent {
  width: 200px;
  font-family: $ff-sans !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
