@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  border-bottom: 1px solid $color-vape;
  padding: 3rem 1rem 5rem 1rem;
}

.formContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 35px;
}
