@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 15px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-top: 10px;

  &.hideContent {
    opacity: 0;
  }
}

.doughnut {
  position: relative;
  width: 64px;
  height: 64px;
}

.doughnutText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7rem;
  text-align: center;
  transform: translate(-50%, -50%);
}

.simulationInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.simulationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  word-wrap: nowrap;

  .simulationItemDot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-oh-so-green;
  }

  .red {
    background-color: $color-singularity-red;
  }

  p {
    width: 87px;
    margin-left: 4px;
    color: $color-ashy;
    font-size: 1.1rem;
    line-height: 1.3rem;
    white-space: nowrap;
  }

  .height18 {
    line-height: 1.6rem;
  }

  span {
    width: 86px;
    margin-left: 11px;
    padding: 4px;
    border-radius: 35px;
    background-color: $color-ashy;
    color: $color-white;
    font-size: 1.1rem;
    line-height: 1.2rem;
    text-align: center;
  }
}

.simulationItemMarginBottom {
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .content {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .simulationItem {
    flex-direction: column;
    span {
      margin-left: 0px;
    }
  }
}
