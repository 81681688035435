.newMember {
  padding: 0px 14px 0px 14px;

  .newMemberForm {
    padding: 5px 0px;
  }
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 43px;
}

.formButton {
  width: 100%;
}
