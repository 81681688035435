form {
  .fieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }
}

.dataStreamId {
  margin-top: 25px;
}
