@import 'src/assets/styles/utils/index.module.scss';

.managerTile {
  position: relative;
  display: flex;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: thin solid $color-vape;
  border-radius: 4px;
  background-color: $color-vape;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 17px;
    background-color: $color-oh-so-green;
    transform: translateY(-50%);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.menuIcon {
  cursor: pointer;
}
