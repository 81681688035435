@import 'src/assets/styles/utils/index.module.scss';

.profileMobile {
  display: flex;
  flex-direction: row;
  background-color: $color-pretty-dark;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 40px;
  .profileIconArea {
    display: flex;
    font-size: 25px;
    justify-content: center;
    align-items: center;
  }
  .profileMobileContent {
    flex-direction: column;

    p {
      color: $color-white;
      font-size: 14px;
      margin-bottom: 10px;
      &.greenText {
        color: $color-oh-so-green;
      }
    }
  }
}
